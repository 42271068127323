<template>
	<section id="administrations" class="list-administrations">
		<b-row style="justify-content: space-between" class="mb-2 align-center">
		    <b-col>
				<Breadcrumb :labels="labels" />				
		    </b-col>
		    <b-col>
		    	<div class="icon-search-wrapper ml-auto" style="max-width: 346px; width: 100%;">
			      	<b-form-group class="mb-0">
				        <b-input-group class="input-group-merge">
					        <b-input-group-prepend is-text>
					            <feather-icon icon="SearchIcon" size="17" />
					        </b-input-group-prepend>
					        <b-form-input
					        	type="text"
					            placeholder="Search"
					            @input="searchValue"
					        />
				        </b-input-group>
			      	</b-form-group>
			    </div>			    
		    </b-col>
		</b-row>
		<template v-if="tabExpert.includes(tabIndex)">
			<b-button type="button" variant="primary" class="btn-df size-18 black cursor-pointer d-block ml-auto"
				style="background-color: #8471FF !important; z-index: 5; margin-bottom: -50px; position: relative "
				@click="gotoAddExpert()">
				Add Expert
			</b-button>
		</template>
		
		<b-row class="list-item">
		    <b-col md="12"> 
		    	<b-tabs card class="caption-add table-custom w-10-percent-cl-1 w-20-percent-cl-2 w-25-percent-cl-3 w-20-percent-cl-4" v-model="tabIndex">
		            <b-tab title="Overview" class="list-tab">
		            	<Overview ref="tab_0" :search="getSearch" :permission="permission" />
		            </b-tab>   
		            <b-tab title="External" class="tab-child tab-pt-0">
		            	<External ref="tab_1" :search="getSearch" :permission="permission" />
		            </b-tab> 
		            <b-tab title="Ex-ITE" class="tab-child tab-pt-0">	
		            	<exIte ref="tab_2" :search="getSearch" :permission="permission" />
		            </b-tab> 
		            <b-tab title="ITE" class="tab-child tab-pt-0">	
		            	<Ite ref="tab_3" :search="getSearch" :permission="permission" />
		            </b-tab> 
		            <b-tab title="Rejected" class="tab-child tab-pt-0">	
		            	<Rejected ref="tab_4" :search="getSearch" :permission="permission" />
		            </b-tab>                   
		        </b-tabs>  
		    </b-col>
		</b-row>
	</section>	
 
</template>

<script>
	import Breadcrumb from '../partial/Breadcrumb'
	import Overview from './overview/List'
	import External from './external/List'
	import exIte from './ex-ite/List'
	import Ite from './ite/List'
	import Rejected from './rejected/List'
	import globalAdmin from '../model/globalAdmin'

	export default {
		mixins: [globalAdmin],
	  	components: {
	  		Breadcrumb,
	  		Overview,
	  		External,
	  		exIte,
	  		Ite,
	  		Rejected
	  	},
	  	data() {
		    return {	
				userData: JSON.parse(localStorage.getItem('userData')),
				permission: [],
		    	labels: { "experts-applications": "" },
			    tabIndex: 0,
			    getSearch: null,
				tabExpert: [1,2,3]
		    }
		},
		mounted() {
            this.$nextTick(() => {
                this.loadDataToTab(this.getSearch)
            })
        },
		watch: {      
			"$route": function(val) {
				if(this.$route && this.$route.query && this.$route.query.type) this.tabIndex = Number(this.$route.query.type)
			},   
            getSearch(val) {
                this.loadDataToTab(val)                
            },           
            tabIndex: function(val) {
                this.loadDataToTab(this.getSearch)
            },
        },
        created(){
			this.permission = this.checkPermission(this.userData,"TVET Expert Application") ?? [];
			if(this.$route && this.$route.query && this.$route.query.type) this.tabIndex = Number(this.$route.query.type)
		},
		methods: {
			searchValue(val){
				this.getSearch = val
			},
			loadDataToTab(val) {
                this.$refs[`tab_${this.tabIndex}`].currentPage = 1
                this.$refs[`tab_${this.tabIndex}`].fetchList(val)
            },
			gotoAddExpert(){
				switch(this.tabIndex){
					case 1: 
						window.open(`${process.env.VUE_APP_URL_WP}/external-form/`, '_blank');
						break; 
					case 2: 
						window.open(`${process.env.VUE_APP_URL_WP}/ex-ite-staff-form/`, '_blank');
						break; 
					case 3: 
						window.open(`${process.env.VUE_APP_URL_WP}/ite-staff-form//`, '_blank');
						break; 
				}
				
			}
		}
	};	

</script>

<style lang="scss">	
	@import "@/assets/scss/_ite_tab_table.scss";
	
</style>
